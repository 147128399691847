import { ConfigProvider } from "antd";
import AppLayout from "./pages/layout/AppLayout";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { useEffect } from "react";
import { get } from "./services/api";
import { setUser } from "./features/user";
import { useDispatch, useSelector } from "react-redux";
import Registration from "./pages/Registration";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    get("/users/me").then((user_data) => {
      if(user_data.human) {
        user_data.status = !user_data.human.checkedIn ? 'out' : !user_data.human.onBreak ? 'working' : 'break'; 
      }
      dispatch(setUser(user_data));
    }).catch(() => {
    })
  }, []);

  return (
    <ConfigProvider
    theme={{
      components: {
        Slider: {
          handleSize: 4,
          handleSizeHover: 4,
          dotSize: 4
        }
      },
      token: {
        fontFamily: "'Hanken Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        fontSize: 14,
        },
      }}>
      <BrowserRouter>
        {
          user && user.id ? (
            <AppLayout />
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              {/* Redirect any other path to Login */}
              <Route path="*" element={<Login />} />
            </Routes>
          )
        }
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
