import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import { get, post, slugify } from '../services/api';
import { setProjects } from '../features/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setList } from '../features/list';

const { TextArea } = Input;
const { Option } = Select;

const CreateProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const user = useSelector((state) => state.user);
  const [defaultListAdded, setDefaultListAdded] = useState(false);
  const projects = useSelector((state)=>state.projects)
 

  const onFinish = async (values) => {
    const project = await post(`users/${user.id}/projects`, values);
    const updatedProjects = await get(`users/${user.id}/projects`);
    dispatch(setProjects(updatedProjects));
    navigate(`/${slugify(project.name)}`);
  };

  return (
    <Form
      style={{ padding: '10px 20px' }}
      form={form}
      name="create_project"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        remember: true,
      }}
      autoComplete="off"
    >
      <Form.Item
        label="Project Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input the project name!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: false,
            message: 'Please input the project description!',
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        label="Start Date"
        name="startDate"
        rules={[
          {
            required: false,
            message: 'Please select the project start date!',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="End Date"
        name="endDate"
        rules={[
          {
            required: false,
            message: 'Please select the project end date!',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Priority"
        name="priority"
        rules={[
          {
            required: false,
            message: 'Please select the project priority!',
          },
        ]}
      >
        <Select>
          <Option value="high">High</Option>
          <Option value="medium">Medium</Option>
          <Option value="low">Low</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create Project
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProject;
