import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from '../features/user';
import { projectsReducer } from '../features/projects';
import { usersReducer } from '../features/users';
import { notificationsReducer } from '../features/notifications';
import { humansReducer } from '../features/humans';
import { assetsReducer } from '../features/assets';
import { servicesReducer } from '../features/services';
import { currentTimeLogReducer, } from '../features/currentTimeLog';
import { listReducer } from '../features/list';
import { roomReducer } from '../features/room';
import { meetingEventsReducer } from '../features/meetingEvent';
import { taskEventsReducer } from '../features/taskEvents';

const store = configureStore({
  reducer: {
    user: userReducer,
    projects: projectsReducer,
    users: usersReducer,
    notifications: notificationsReducer,
    humans:humansReducer,
    assets:assetsReducer,
    services:servicesReducer,
    currentTimeLog: currentTimeLogReducer,
    list: listReducer,
    room: roomReducer,
    meetingEvents: meetingEventsReducer,
    taskEvents: taskEventsReducer
  },
});

export default store;