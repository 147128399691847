import { Flex } from "antd"
import { useLocation, useNavigate } from "react-router-dom";
import Messages from "../components/Messages";
import Tasks from "../components/Tasks";
import { useEffect, useState } from "react";
import SendMessageInput from "../components/SendMessageInput";
import CreateTaskInput from '../components/CreateTaskInput';
import { useSelector } from "react-redux";
import { get, post, xdelete } from "../services/api";

export default function AttendedTasks () {
  // const user = useSelector((state) => state.user);
  // const users = useSelector((state) => state.users);
  // const [tasks, setTasks] = useState([]);
  // const currentTimeLog = useSelector((state) => state.currentTimeLog);

  // useEffect(() => {
  //   if(!currentTimeLog) { refreshTasks(); }
  // }, [currentTimeLog]);

  // const refreshTasks = () => {
  //   get(`/users/${user.id}/tasks`, {
  //     include: [{
  //       relation: 'taskAccesses'
  //     }, {
  //       relation: 'timeLogs',
  //       scope: {
  //         order: ['createdAt DESC'] // Add sorting here
  //       }
  //     },{
  //       relation : 'list',
  //       scope : {
  //         include : [{
  //           relation : 'project'
  //         }]
  //       }
  //     }],
  //     order: ['dueDate ASC'],
  //     where: {
  //       status: { neq: 'done' }, // Exclude tasks where status is "done"
  //     },
  //   }).then(tasks => {
  //     // Move tasks with no dueDate to the end
  //     const sortedTasks = tasks.sort((a, b) => {
  //       if (!a.dueDate && b.dueDate) return 1; // a has no dueDate, move it down
  //       if (a.dueDate && !b.dueDate) return -1; // b has no dueDate, move a up
  //       return 0; // both have dueDates, keep the original order
  //     });
  //     setTasks(sortedTasks);
  //   })
  // }

  // const updateTask = async (tasks) => {
  //   setTasks(tasks);
  // }

  // const deleteTasks = async (tasksIds) => {
  //   var res = await xdelete(`/users/${user.id}/tasks`, {
  //     id: {
  //       inq: tasksIds
  //     }
  //   });
  //   if(res.count > 0) {
  //     refreshTasks();
  //   }
  // }

  // useEffect(() => {
  //   refreshTasks();
  // }, [user.id]);

  return <div>
    {/* <div className="left-bg-overlay">
      <Button type="primary" icon={<MessageOutlined />} size={'large'}  onClick={openChat} />
      <Button type="primary" icon={<CalendarOutlined  />} size={'large'} />
      <Button type="primary" icon={<UnorderedListOutlined />} size={'large'} />
    </div> */}
    <Tasks list={null} custom={'mine'} />
  </div>
}