import { createSlice } from '@reduxjs/toolkit';

const currentTimeLogSlice = createSlice({
  name: 'currentTimeLog',
  initialState: null,
  reducers: {
    setCurrentTimeLog: (state, action) => {
      return action.payload;
    },
    mayRemoveCurrentTimeLog: (state, action) => {
      if(state.id === action.payload.id) {
        return null;
      }
    },
  },
});

export const currentTimeLogReducer = currentTimeLogSlice.reducer;

// Action creators are generated for each case reducer function
export const { setCurrentTimeLog, mayRemoveCurrentTimeLog } = currentTimeLogSlice.actions;