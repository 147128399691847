import { DatePicker } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "antd/dist/reset.css";

// Extend Day.js with the relativeTime plugin
dayjs.extend(relativeTime);

export default function DueDatePicker({ task, onUpdateDueDate }) {

  const handleChange = (value) => {
    if(value) {
      onUpdateDueDate(value);
    } else {

    }
  };

  // Format the date in relative time
  const formatRelativeTime = (date) => {
    return date ? dayjs(date).endOf('day').fromNow() : '';
  };

  // Determine if the date is in the past
  const isPastDate = task.status !== 'done' && task.dueDate && dayjs(task.dueDate).isBefore(dayjs(), 'day');

  return (
    <DatePicker
      value={task.dueDate ? dayjs(task.dueDate) : null}
      onChange={handleChange}
      suffixIcon={null}
      allowClear={false}
      variant="borderless"
      placeholder="Due Date"
      format={(value) => formatRelativeTime(value)}
      style={{
        borderColor: isPastDate ? 'red' : undefined, // Change border color if in the past
        color: isPastDate ? 'red' : undefined, // Change text color if in the past
        fontWeight: 'bold'
      }}
      status={isPastDate ? "error" : undefined} // Set warning status if in the past
    />
  );
}
