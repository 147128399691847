import { createSlice } from '@reduxjs/toolkit';

const meetingEventsSlice = createSlice({
  name: 'meetingEvent',
  initialState: [],
  reducers: {
    addMeetingEvent: (state, action) => {
      // Add a new event
      state.push(action.payload);
    },
    setMeetingEvents: (state, action) => {
      return [...action.payload];
    },
  },
});

export const meetingEventsReducer = meetingEventsSlice.reducer;

// Action creators are generated for each case reducer function
export const { setMeetingEvents, addMeetingEvent } = meetingEventsSlice.actions;