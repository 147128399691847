import { Select } from "antd";
import { CheckCircleOutlined, SyncOutlined, ClockCircleOutlined } from "@ant-design/icons";

export default function StatusPicker({ task, onUpdateStatus }) {
  const handleChange = (value) => {
    onUpdateStatus(value);
  };

  // Define the styles and icons for each status
  const getStatusStyle = (status) => {
    switch (status) {
      case "todo":
        return { color: "#0000FF", backgroundColor: "#0000FF", icon: <ClockCircleOutlined /> }; // Blue for Todo
      case "doing":
        return { color: "#ff398b", backgroundColor: "#ff398b", icon: <SyncOutlined spin /> }; // Pink for Doing
      case "done":
        return { color: "#389E0D", backgroundColor: "#389E0D", icon: <CheckCircleOutlined /> }; // Green for Done
      default:
        return { color: "black", backgroundColor: "white", icon: null };
    }
  };

  const currentStatus = getStatusStyle(task.status);

  return (
    <Select
      value={task.status}
      suffixIcon={null}
      onChange={handleChange}
      style={{
        backgroundColor: currentStatus.backgroundColor,
        minWidth: "120px",
        textAlign: "center",
        fontWeight: "bold",
        color: "#fff",
        borderColor: currentStatus.color,
        borderWidth: "2px",
        borderRadius: "6px",
      }}
      dropdownStyle={{ borderRadius: "8px" }}
      popupMatchSelectWidth={false}
      placement="bottomRight"
      options={[
        {
          value: "todo",
          label: (
            <span style={{ color: "#0000FF" }}>
              <ClockCircleOutlined style={{ marginRight: 8 }} />
              <b>Todo</b>
            </span>
          ),
        },
        {
          value: "doing",
          label: (
            <span style={{ color: "#ff398b" }}>
              <SyncOutlined style={{ marginRight: 8 }} spin />
              <b>Doing</b>
            </span>
          ),
        },
        {
          value: "done",
          label: (
            <span style={{ color: "#389E0D" }}>
              <CheckCircleOutlined style={{ marginRight: 8 }} />
              <b>Done</b>
            </span>
          ),
        },
      ]}
    />
  );
}
