import { PlusOutlined, DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Progress, Popconfirm, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import HumanCreate from '../components/HumanCreate';
import { generateHexDarkColor, get, xdelete } from "../services/api";
import moment from "moment";
import HumanTransactionCreate from "../components/HumanTransactionCreate";
import HumanEdit from "../components/HumanEdit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Humans() {
  const [humans, setHumans] = useState([]);
  const [human, setHuman] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTransactModalOpen, setIsTransactModalOpen] = useState(false);
  const users = useSelector((state) => state.users);

  const columns = [  
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, human) => {
        const user = users.find(u => u.id == human.userId);
        return <div className="avatar-with-profile">
          { human.userId && <Avatar size={40} style={{ backgroundColor: generateHexDarkColor(human.userId), boxSizing: "border-box" }}>
            {user.firstName[0]}{user.lastName[0]}
          </Avatar> }
          { !human.userId && <Avatar size={40} style={{ minWidth: "40px" }} icon={<UserOutlined />} /> }
          <div className="heading-with-tagline">
            <Link to={"/sample/attendance#"+human.userId}>{name}</Link>
            <span>{human.position}</span>
          </div>
        </div>
      },
    },
    {
      title: 'Salary',
      dataIndex: 'ctc',
      render: (value, human) => {
        return <div className="heading-with-tagline">
          <Typography.Text className="amount" type={value < 0 ? "primary" : "primary"}>{
            new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(Math.abs(value / 12))
          }</Typography.Text>
          <span>monthly</span>
        </div>
      }
    },
    {
      title: 'Service Expiring',
      render: (human) => {
        let validTill = null;
        if (human.transactions && human.transactions.length) {
          validTill = human.transactions[0].endAt;
        }
        const remainingDays = moment().add(12, 'months').diff(moment(validTill), 'days');
        const percent = remainingDays * 100 / 365;
        let status = 'active';
        if (percent < 25) { status = 'success' }
        if (percent > 75) { status = 'exception' }
        return <div className="heading-with-tagline">
          <Progress percent={percent} showInfo={false} status={status} />
          <span>{validTill ? moment(validTill).fromNow() : null}</span>
          {/* <span>{validTill ?  : '' }</span> */}
        </div>
      }
    },
    {
      title: 'Actions',
      dataIndex: '',
      render: (human) => {
        return <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Button onClick={() => showTransactModal(human)} type="default" shape="circle" icon={<PlusOutlined />}></Button>
            <Button onClick={() => showEditModal(human)} type="default" shape="circle" icon={<EditOutlined />}></Button>
            <Popconfirm
              title="Delete Human"
              placement="bottomRight"
              description="Are you sure to delete this Human?"
              onConfirm={() => deleteHuman(human)}
              onCancel={console.log}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
            {/* <Button onClick={() => deleteHuman(human)} type="default" shape="circle" icon={<DeleteOutlined />}></Button> */}
            </div>
        </>
      }
    },
  ]
  
  const deleteHuman = async (human) => {
    await xdelete(`/humans/${human.id}`);
    await refreshHumans();
  };

  const showCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const showEditModal = (human) => {
    setHuman(human)
    setIsEditModalOpen(true);
  };
  const showTransactModal = (human) => {
    setHuman(human);
    setIsTransactModalOpen(true);
  };
  const refreshHumans = async () => {
    return get("/humans", {
      include: [{
        relation: "transactions",
        scope: {
          limit: 1,
          order: 'endAt DESC'
        }
      }]
    }).then(setHumans);
  }
  const onHumanCreate = async () => {
    await refreshHumans();
  }
  const onHumanEdit = async () => {
    await refreshHumans();
  }
  const onHumanTransactionCreate = async () => {
    setHuman(null);
    await refreshHumans();
  }
  const selectType = async (type) => {
    if (type === 'all') { return await onHumanCreate() }
    const humans = await get('/humans?filter={"where":{"type":"' + type + '"}}');
    return setHumans(humans);
  }
  useEffect(() => {
    refreshHumans();
  }, []);
  
  return <Flex gap={20}>
    <div style={{ marginLeft: '16px' }}>
      <Button type="primary" className="align bold" icon={<PlusOutlined />} style={{ margin: "10px 0 36px" }} onClick={showCreateModal}>
        Add Human
      </Button>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        size="small"
        style={{ width: "200px" }}
        onChange={selectType}
        items={[{
          label: `All`,
          key: 'all'
        }]}
      />
    </div>
    <Table style={{ flex: '1' }} scroll={{ y: window.innerHeight - 120 }} pagination={false} dataSource={humans} rowKey={'id'} columns={columns} />
    <HumanCreate isModalOpen={isCreateModalOpen} setIsModalOpen={setIsCreateModalOpen} onHumanCreate={onHumanCreate} />
    <HumanEdit isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} onHumanEdit={onHumanEdit} human={human} setHuman={setHuman}/>
    <HumanTransactionCreate isModalOpen={isTransactModalOpen} setIsModalOpen={setIsTransactModalOpen} human={human} setHuman={setHuman} onHumanTransactionCreate={onHumanTransactionCreate} />
  </Flex>
}