import { Avatar, Flex, Table, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generateHexDarkColor, generateHexLightColor, get } from '../services/api';
import { PauseCircleFilled, UserOutlined } from '@ant-design/icons';

const {RangePicker} = DatePicker;

// Helper function to format seconds into HH:mm:ss
function formatDuration(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m, s].map(v => String(v).padStart(2, '0')).join(':');
}

// Group by userId, taskId, and createdAt date (without time)
const groupedData = (data, users) => Object.values(data.reduce((acc, item) => {
  const userId = item.userId;
  const taskId = item.taskId;
  const date = dayjs(item.createdAt).format('YYYY-MM-DD'); // Extract date without time
  // Calculate duration between createdAt and stoppedAt in seconds
  const diffInSeconds = dayjs(item.stoppedAt || Date.now()).diff(dayjs(item.createdAt), 'second');
  const billing = item.stoppedAt ? item.billing : item.billing*diffInSeconds || 0;

  // Initialize user group if not exists
  if (!acc[userId]) {
      acc[userId] = {
          userId,
          tasks: {},
          totalDurationByDate: {}, // Store total duration for each date across tasks
          totalBillingByDate: {} // Store total billing for each date across tasks
      };
  }

  // Initialize task group if not exists
  if (!acc[userId].tasks[taskId]) {
      acc[userId].tasks[taskId] = {
          taskId: taskId,
          currentTask: !item.stoppedAt ?? taskId,
          task: item.task, // Keep complete task details
          logsByDate: {} // Group logs by date
      };
  }

  // Initialize date group for task if not exists
  if (!acc[userId].tasks[taskId].logsByDate[date]) {
      acc[userId].tasks[taskId].logsByDate[date] = {
          logs: [],
          totalDuration: 0, // Store total duration for this date in seconds (per task)
          totalBilling: 0
      };
  }

  // Add item to the respective task date group
  acc[userId].tasks[taskId].logsByDate[date].logs.push(item);

  // Add the duration to the total duration for the date (per task)
  acc[userId].tasks[taskId].logsByDate[date].totalDuration += diffInSeconds;
  acc[userId].tasks[taskId].logsByDate[date].totalBilling += billing;

  // Add the duration to the total duration for the date (across tasks for this user)
  if (!acc[userId].totalDurationByDate[date]) {
    acc[userId].totalDurationByDate[date] = 0;
  }
  if (!acc[userId].totalBillingByDate[date]) {
      acc[userId].totalBillingByDate[date] = 0;
  }
  acc[userId].totalDurationByDate[date] += diffInSeconds;
  acc[userId].totalBillingByDate[date] += billing;

  return acc;
}, {})).map(user => {
  console.log(user);
  // Convert the grouped tasks and logs into the required array format
  const tasksArray = Object.values(user.tasks).map(task => ({
      key: user.userId+"-"+task.taskId,
      taskTitle: task.task.title,
      taskTransaction: task.task.taskTransactions,
      logsByDate: Object.keys(task.logsByDate).map(date => ({
          date,
          logs: task.logsByDate[date].logs,
          totalDurationInSeconds: task.logsByDate[date].totalDuration, // Add total duration for the task on this date
          totalBilling: task.logsByDate[date].totalBilling
      })),
  }));

  // Convert total duration by date into an array
  const totalDurationByDate = Object.keys(user.totalDurationByDate).map(date => ({
      date,
      totalDurationInSeconds: user.totalDurationByDate[date], // Total duration across all tasks for this date
      totalBilling: user.totalBillingByDate[date]
  }));

  return { 
    key: user.userId, 
    user: users.find(u => u.id == user.userId), 
    children: tasksArray, 
    totalDurationByDate
  };
});

const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

export default function Report() {
  const [logs, setLogs] = useState([]);
  const users = useSelector((state) => state.users);
  const today = [dayjs().startOf('day'), dayjs().endOf('day')];
  const [dateRange, setDateRange] = useState(today);

  // Fetch time logs with date range filtering
  async function refreshTimeLogs(dates) {
    let whereClause = {};

    // Check if dates are provided
    if (dates && dates.length === 2 && dates[0] && dates[1]) {
      whereClause = {
        createdAt: {
          gt: dates[0].startOf('day').toISOString(),
          lt: dates[1].endOf('day').toISOString()
        }
      };
    }

    const timeLogs = await get('/time-logs', {
      include: [{ relation: 'task' }],
      order: ['createdAt ASC'],
      where: whereClause
    });

    setLogs(groupedData(timeLogs, users));
  }

  useEffect(() => {
    refreshTimeLogs(today);
  }, []);

  // Handle date range change
  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    refreshTimeLogs(dates);
  };

  // Define columns dynamically based on dates
  const columns = [
    {
      title: <>
        <RangePicker value={dateRange} onChange={handleDateRangeChange} />
      </>,
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => {
        let billing = (record.totalDurationByDate || record.logsByDate).reduce(
          (sum, t) => sum + t.totalBilling,
          0
        ).toFixed(2);
        console.log(record);
        return record.user?.firstName ? <><div className="avatar-with-profile">
          {/* <Avatar size={40} style={{ minWidth: "40px" }} icon={<UserOutlined />} /> */}
          <Avatar style={{ backgroundColor: generateHexDarkColor(record.user.id) }}>{record.user.firstName[0]}{record.user.lastName[0]}</Avatar>
          <div className="heading-with-tagline">
            <a href="#">{record.user?.firstName} {record.user?.lastName}
              {billing > 0 && <span style={{ color: 'green', marginLeft: '5px', fontSize: "14px" }}>{`(₹${billing})`}</span>}
            </a>
            <Flex>
              <span>{formatDuration(record.totalDurationByDate.reduce(
                (sum, t) => sum + t.totalDurationInSeconds,
                0
              ))}</span>
            </Flex>
          </div>
        </div></> : <>
          <Avatar shape="circle" size={8} style={{backgroundColor: generateHexLightColor(record.key.toString()), position: 'relative', top: '-1px', left: '-10px', }} /> {record.taskTitle} 
          {billing > 0 && <span style={{ color: 'green', backgroundColor: "rgb(236, 255, 236)", padding: "1px 8px", borderRadius: "8px", marginLeft: "20px", fontSize: "12px" }}>
              {/* {`₹ ${billing*100000}`} */}
              { formatter.format(billing) }
          </span> }
          {record.taskTransaction && record.taskTransaction.length > 0 && <span style={{ color: 'blue', backgroundColor: "rgb(236, 236, 255)", padding: "1px 8px", borderRadius: "8px", marginLeft: "20px", fontSize: "12px" }}>
              {/* {`₹ ${billing*100000}`} */}
              { formatter.format(record.taskTransaction.reduce(
                  (sum, t) => sum + t.billing,
                  0
                ).toFixed(2)) }
          </span> }
        </>
      }
    },
    ...Array.from(new Set(logs.flatMap(user => user.totalDurationByDate.map(date => date.date)))).map(date => ({
      title: dayjs(date).format('ddd, MMM D'),
      dataIndex: date,
      key: date,
      render: (_, record) => {
        // Render user row duration
        const totalDuration = record.totalDurationByDate?.find(d => d.date === date);
        if (totalDuration) return <>
          {formatDuration(totalDuration.totalDurationInSeconds)} { totalDuration.totalBilling > 0 && <span style={{ color: 'green', marginLeft: '5px' }}>{`(₹${totalDuration.totalBilling.toFixed(2)})`}</span> }
        </>

        // Render task row duration
        const logsByDate = record.logsByDate?.find(d => d.date === date);
        let isPlaying = false;
        if(logsByDate && logsByDate.logs.find(l => !l.stoppedAt)) {
          isPlaying = true;
        }
        return logsByDate ? (
          <>
            <span style={{ fontSize: '12px', color: isPlaying ? '#ff398b' : 'black', fontWeight: isPlaying ? 700 : 500 }}>{formatDuration(logsByDate.totalDurationInSeconds)}</span> {/* Smaller size for duration */}
            {logsByDate.totalBilling > 0 && (
              <span style={{ color: 'green', marginLeft: '5px' }}>{`(₹${logsByDate.totalBilling.toFixed(2)})`}</span>
            )}
          </>
        ) : '-';
      }
    })),
  ];

  return (
    <>
      <Table
        showHeader={true}
        expandable={{
          expandRowByClick: true,
          expandIcon: () => { return null },
          indentSize: 32
        }}
        className="report"
        columns={columns}
        dataSource={logs}
        pagination={false}
        rowKey={(record) => record.key}
      />
    </>
  );
}
