import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from '../services/api';
import { Flex, Table, Tooltip } from 'antd'; // Assuming we're using some UI library for layout
import moment from 'moment/moment';

const Timeline = ({ events }) => {
  // Function to convert date-time to minutes since start of the day
  const getMinutesSinceStartOfDay = (dateTime) => {
    const date = dateTime ? new Date(dateTime) : new Date();
    return date.getHours() * 60 + date.getMinutes();
  };

  return (
    <div className="timeline-container">
      {events.map((event) => {
        const startMinutes = getMinutesSinceStartOfDay(event.startAt);
        const endMinutes = getMinutesSinceStartOfDay(event.endAt);
        const duration = endMinutes - startMinutes;
        // console.log({event, duration});
        const widthPercentage = (duration / 1440) * 100;
        const leftPercentage = (startMinutes / 1440) * 100;

        return (
          <Tooltip key={event.id} title={`${moment(event.startAt).format('HH:mm')} - ${event.endAt ? moment(event.endAt).format('HH:mm') : 'Now'}`}>
            <div
              className={`timeline-event ${event.event}`}
              style={{
                left: `${leftPercentage}%`,
                width: `${widthPercentage}%`,
              }}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default function Attendance() {
  const user = useSelector((state) => state.user);
  const [attendances, setAttendances] = React.useState([]);

  useEffect(() => {
    get(`/users/${window.location.hash.slice(1) || user.id}/attendances`).then((data) => {
      setAttendances(data);
    });
  }, [user.id]);

  const groupedEvents = attendances.reduce((acc, event) => {
    const date = new Date(event.startAt).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  const columns = [
    {
      title: 'Date',
      width: '200px',
      render: ({ date }) => {
        return (
          <div className="heading-with-tagline">
            <a href="#">{date}</a>
            <span></span>
          </div>
        );
      },
    },
    {
      title: 'Work Diary',
      render: ({ events }) => {
        return (
          <div className="heading-with-tagline">
            <Timeline events={events} />
          </div>
        );
      },
    },
  ];

  const dataSource = Object.keys(groupedEvents).map((date) => ({
    date,
    events: groupedEvents[date],
  }));

  return (
    <Flex gap={20}>
      <Table
        style={{ flex: '1' }}
        scroll={{ y: window.innerHeight - 120 }}
        pagination={false}
        dataSource={dataSource}
        rowKey={'date'}
        columns={columns}
      />
    </Flex>
  );
}