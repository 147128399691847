import React, { useEffect, useState } from 'react';
import { Avatar, Button, Divider, Flex, Typography } from 'antd';
import { motion } from "framer-motion";
import { get } from '../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setNotifications } from "../features/notifications";
import SendMessageInput from './SendMessageInput';
import { useLocation, useNavigate } from 'react-router-dom';

const Messages = ({list, showChat}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const location = useLocation();

  const [messages, setMessages] = useState([]);
  const notifications = useSelector((state) => state.notifications);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const refreshMessages = () => {
    get(`/lists/${list.id}/messages`, {
      order: 'id DESC'
    }).then((messages) => {
      setMessages(messages);
    });
  }

  const getName = (id) => {
    const user = users.find(u => u.id === id);
    return user.firstName + " " + user.lastName;
  }

  const getAvatar = (id) => {
    const user = users.find(u => u.id === id);
    return user.firstName[0] + user.lastName[0];
  }

  const toggleChat = () => {
    navigate(".", {
      state: {
        chat: !location.state.chat
      },
      replace: true
    });
  }

  useEffect(() => {
    dispatch(setNotifications(notifications.filter(n => n.listId !== list.id)));
    refreshMessages();
    return () => {
      setMessages([]);
    }
  }, [list.id]);

  useEffect(() => {
    var ns = notifications.filter(n => n.listId === list.id);
    if(ns.length) {
      setMessages([
        ...ns.reverse(),
        ...messages,
      ])
      dispatch(setNotifications(notifications.filter(n => n.listId !== list.id)));
      if(!location.state.chat) {
        navigate(".", {
          state: {
            chat: true
          },
          replace: true
        });
      }
    }
  }, [notifications]);

  return <motion.div style={{ transform: "translateX(-100%)" }} className={ (showChat ? 'chatOpen' : '') + ' chat-container' } initial={{
      x: "-120%"
    }} animate={{
      x: showChat ? -180 : "-120%"
    }} transition={{
      duration: 0.3, 
      type: "spring",
      stiffness: 100
    }}>
    {/* <div style={{backgroundColor:"white", height:"40px", borderRadius:"10px 10px 0px 0px", borderBottom:"1px solid #d8d8d8"}}>
    <PhoneTwoTone style={{padding:"10px", fontSize:"20px", position:"absolute", right:"80px"}} />
    <VideoCameraTwoTone style={{padding:"10px", fontSize:"20px", position:"absolute", right:"30px"}} />
    </div> */}
    <Flex vertical style={{ height: "100%" }}>
      <Flex vertical style={{ flex: 1, flexDirection: "column-reverse", overflowY: "scroll", padding: "20px 25px 20px 20px" }} gap={2}>
        &nbsp;
        { messages.map((message, i) => {
          const next_message = messages[i+1];
          let elem;
          if (message.userId === user.id) {
            elem = <Flex justify='flex-end' vertical align='end'>
              { (!next_message || (next_message && (next_message.userId !== message.userId || (next_message.at.slice(0,10) !== message.at.slice(0,10))))) && <Typography.Text type="secondary"><small>17:49</small></Typography.Text> }
              {/* <div className='chat-message me'> */}
                {/* {message.content} */}
                <div className='chat-message me' dangerouslySetInnerHTML={{ __html: message.content }} />
              {/* </div> */}
            </Flex>
          } else {
            elem = <Flex justify='flex-start' vertical align="baseline" style={{position: "relative"}}>
              { (!next_message || (next_message && (next_message.userId !== message.userId || (next_message.at.slice(0,10) !== message.at.slice(0,10))))) && 
                <>
                  <Avatar style={{ position: "absolute", top: "10px", left: "0px", backgroundColor: '#b336ba' }}>{getAvatar(message.userId)}</Avatar>
                  <Typography.Text type="secondary"><small>{getName(message.userId)}, 17:49</small></Typography.Text>
                </>
              }
              <div className='chat-message' dangerouslySetInnerHTML={{ __html: message.content }} />
              {/* <div className='chat-message'>
                {message.content}
              </div> */}
            </Flex>
          }
          return <React.Fragment key={i}>
            {elem}
            { (!next_message || (next_message.at.slice(0,10) !== message.at.slice(0,10))) &&     <Divider plain style={{ margin: '15px 0' }}><small>{message.at.slice(0,10)}</small></Divider> }
          </React.Fragment>;
        }) }
      </Flex>
      <SendMessageInput user={user} list={list}/>
    </Flex>
   
  </motion.div>
};

export default Messages;
