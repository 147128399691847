import { useDispatch, useSelector } from "react-redux";
import MeetingCard from "./MeetingCard";
import { slugify } from "../services/api";
import { useEffect } from "react";
import { setMeetingEvents } from "../features/meetingEvent";
import { setNotifications } from "../features/notifications";
import { useNavigate } from "react-router-dom";

export default function MeetingCards() {
  const notifications = useSelector((state) => state.notifications);
  const meetingEvents = useSelector((state) => state.meetingEvents);
  const dispatch = useDispatch();
  console.log(meetingEvents);
  // Group notifications by ID and count duplicates
  const notificationCounts = notifications.reduce((acc, item) => {
    acc[item.id] = (acc[item.id] || 0) + 1;
    return acc;
  }, {});
  
  // Filter unique notifications
  const uniqueNotifications = [
    ...new Map(notifications.map((item) => [item.id, item])).values(),
  ];

  useEffect(() => {
    console.log("meeting events changed");
    setMeetingEvents(meetingEvents.filter((e) => {
      console.log(e);
      switch(e.method) {
        case 'DELETE':    
          dispatch(setNotifications(notifications.filter(n => n.id !== e.data.id)));
          window.history.back();
          return false;
        default:
          return true;
      }
    }));
  }, [meetingEvents.length]);

  return (
    <div style={{ maxHeight: "50vh", overflow: "scroll" }}>
      {uniqueNotifications
        .filter((n) => n.type === 'meeting')
        .map((n) => (
          <MeetingCard
            scheduledAt={n.scheduledAt}
            key={n.id}
            project={n?.task?.list?.project?.name}
            list={n?.task?.list?.name}
            taskId={n?.task?.id}
            agenda={n?.task?.title}
            members={n?.task?.taskAccesses || []}
            notified={notificationCounts[n.id]-1} // Pass the count to MeetingCard
          />
        ))}
    </div>
  );
}