import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUsers: (state, action) => {
      return action.payload;
    },
    addUser: (state, action) => {
      state.push(action.payload);
    },
    updateUser: (state, action) => {
      const index = state.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    deleteUser: (state, action) => {
      return state.filter(user => user.id !== action.payload.id);
    },
  },
});

export const usersReducer = usersSlice.reducer;

// Action creators are generated for each case reducer function
export const { setUsers, addUser, updateUser, deleteUser } = usersSlice.actions;