import { PlusOutlined, UserOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Progress, Popconfirm, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { get, xdelete } from "../services/api";
import moment from "moment";
import ContractCreate from '../components/ContractCreate';
import ContractEdit from "../components/ContractEdit";
import { Content } from 'antd/es/layout/layout';

export default function Contracts () {
  const [contract, setContract] = useState({});
  const [contracts, setContracts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, contract) => {
        console.log(contract);
        return <div className="avatar-with-profile">
          <Avatar size={40} style={{ minWidth: "40px" }} icon={<UserOutlined />} />
          <div className="heading-with-tagline">
            <a href="#">{name}</a>
            <span></span>
          </div>
        </div>
      },
    },
    {
      title: 'Completion',
      render: ({ progress, limit, track, rate, budget, paymentFreq, deadLine, currentTransactionStartDate }) => {
        let percent = progress*100/limit;
        let loss = 0;
        if(paymentFreq === 'week') {
          const total_hours = moment().endOf('isoWeek').diff(moment().startOf('isoWeek'), 'hours');
          const passed_hours = moment().diff(moment().startOf('isoWeek'), 'hours');
          if (currentTransactionStartDate != moment().utc().startOf('isoWeek').toISOString()) { progress = 0; }
          percent = 100*passed_hours/total_hours;
        } else if(paymentFreq === 'month') {
          const total_hours = moment().endOf('month').diff(moment().startOf('month'), 'hours');
          const passed_hours = moment().diff(moment().startOf('month'), 'hours');
          if (currentTransactionStartDate != moment().utc().startOf('month').toISOString()) { progress = 0; }
          percent = 100*passed_hours/total_hours;
        } else if(deadLine) {
          const total_hours = moment(deadLine).endOf('day').diff(moment(currentTransactionStartDate).startOf('day'), 'hours');
          const passed_hours = moment().diff(moment(currentTransactionStartDate).startOf('day'), 'hours');
          percent = 100*passed_hours/total_hours;
        } else {
          percent = 0
        }
        loss = (budget * (percent/100)) - (budget * progress);
        return <div className="heading-with-tagline">
          <Progress percent={percent} success={{ percent: progress*100 }} status='exception' showInfo={false} />
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <span>{progress*(limit || 1)} of {limit} {track}</span>
              { loss > 0 && <> | <span style={{ color: "red" }}>{
                new Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0
                }).format(loss)
              }</span></> }
              { progress*budget > 0 && <> | <span style={{ color: "green" }}>{
                new Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  maximumFractionDigits: 0
                }).format(progress*budget)
              }</span></> }
            </span>
            <span>{
            new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(Math.abs(budget || rate*limit || rate*progress))
          } {paymentFreq}ly</span>
          </span>
        </div>
      }
    },
    {
      title: 'Actions',
      width:"100px",
      render: (contract) => {
        return <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Button onClick={() => showEditModal(contract)} type="default" shape="circle" icon={<EditOutlined />}></Button>
          <Popconfirm
            title="Delete Contract"
            placement="bottomRight"
            description="Are you sure to delete this Contract?"
            onConfirm={() => deleteContract(contract)}
            onCancel={console.log}
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      },
    },
  ]
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showEditModal = (contract) => {
    setContract(contract)
    setIsEditModalOpen(true);
  };
  const onContractEdit = async () => {
    await refreshContracts();
  }
  const deleteContract = async (contract) => {
    await xdelete(`/contracts/${contract.id}`);
    await refreshContracts()
  };

  const refreshContracts = async (contract) => {
    const contracts = await get("/contracts");
    return setContracts(contracts);
  }

  const selectType = async (type) => {
    if (type === 'all') { return await refreshContracts() }
    const contracts = await get('/contracts?filter={"where":{"type":"'+type+'"}}');
    return setContracts(contracts);
  }

  useEffect(() => {
    get("/contracts").then(setContracts);
  }, []);

  return <Flex gap={20}>
    <div style={{ marginLeft: '16px' }}>
      <Button type="primary" className="align bold" icon={<PlusOutlined />} style={{ margin: "10px 0 36px" }} onClick={showModal}>
        Add Contract
      </Button>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        size="small"
        style={{ width: "200px" }}
        onChange={selectType}
        items={[{
          label: `All`,
          key: 'all'
        }]}
      />
    </div>
    <Table style={{ flex: '1' }} scroll={{ y: window.innerHeight-120 }} pagination={false} dataSource={contracts} rowKey={'id'} columns={columns} />
    <ContractCreate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onContractCreate={refreshContracts} />
    <ContractEdit isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} contract={contract} onContractEdit={onContractEdit} setContract={setContract}/>
  </Flex>
}