import { createSlice } from '@reduxjs/toolkit';

const taskEventsSlice = createSlice({
  name: 'taskEvent',
  initialState: [],
  reducers: {
    addTaskEvent: (state, action) => {
      // Add a new event
      state.push(action.payload);
    },
    setTaskEvents: (state, action) => {
      return [...action.payload];
    },
    removeTaskEvent: (state, action) => {
      // Check if an event with the specified ID exists
      const taskEventExists = state.some((taskEvent) => taskEvent.id === action.payload);
      
      // If it exists, filter it out and return the new array; otherwise, return the original state
      return taskEventExists ? state.filter((taskEvent) => taskEvent.id !== action.payload) : state;
    },
    removeTaskEvents: (state, action) => {
      const idsToRemove = action.payload;
      return state.filter((taskEvent) => !idsToRemove.includes(taskEvent.id));
    },
  },
});

export const taskEventsReducer = taskEventsSlice.reducer;

// Action creators are generated for each case reducer function
export const { setTaskEvents, addTaskEvent, removeTaskEvent, removeTaskEvents } = taskEventsSlice.actions;