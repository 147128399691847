import React, { useState, useRef, useEffect } from 'react';
import { Select, Avatar, Flex, Badge } from 'antd';
import { SyncOutlined, UserOutlined } from '@ant-design/icons';
import { generateHexDarkColor } from '../services/api';
import { useSelector } from 'react-redux';

const { Option } = Select;

const AvatarPicker = ({users, onRemoveUser, onAddUser, defaultSelectedUsers, single=false, readOnly=false, size, className="", maxCount = 3, active = ""}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const me = useSelector((state) => state.user);
  const selectRef = useRef();

  // Handler for the Select component value change
  const handleChange = (value) => {
    if(defaultSelectedUsers.length > value.length) { onRemoveUser(defaultSelectedUsers.find(v => value.indexOf(v) === -1)); }
    else if(defaultSelectedUsers.length < value.length) { onAddUser(value.find(v => defaultSelectedUsers.indexOf(v) === -1)); }
    setSelectedValues(value);
  };

  const onDropdownVisibleChange = (flag) => {
    setDropdownVisible(flag);
  }

  // Function to simulate click on the hidden Select when the AvatarGroup is clicked
  const triggerSelect = () => {
    setDropdownVisible(true);
  };

  useEffect(() => {
    // console.log(defaultSelectedUsers);
    setSelectedValues(defaultSelectedUsers);
  }, [defaultSelectedUsers]);

  return (
    <div style={{ display: 'flex', position: "relative", alignItems: 'center', gap: '8px' }}>
      {/* Avatar group that when clicked, triggers the hidden select dropdown */}
      <div onClick={triggerSelect} style={{ cursor: 'pointer', display: "contents" }}>
        <Avatar.Group size={size} max={{count:maxCount}} className={className}>
          { selectedValues.map(value => {
            const user = users.find(u => u.id === value);
            return user && (
              <div key={user.id}>
                {user.status === 1 || user.id == me.id ? (
                  <Badge dot color="green" offset={[-25, 4]}>
                    <Avatar 
                      className={active.indexOf(user.id) >= 0 ? 'active' : ''} 
                      style={{ backgroundColor: generateHexDarkColor(user.id), boxSizing: "border-box" }} 
                      key={value}
                    >
                      {user.firstName[0]}{user.lastName[0]}
                    </Avatar>
                  </Badge>
                ) : (
                  <Avatar 
                    className={active.indexOf(user.id) >= 0 ? 'active' : ''} 
                    style={{ backgroundColor: generateHexDarkColor(user.id), boxSizing: "border-box" }} 
                    key={value}
                  >
                    {user.firstName[0]}{user.lastName[0]}
                  </Avatar>
                )}
              </div>
            )
          }) }
          { selectedValues.length === 0 && <Avatar icon={<UserOutlined />} /> }
        </Avatar.Group>
      </div>
      {/* The hidden Select component */}
      <Select
        ref={selectRef}
        mode="multiple"
        maxCount={single ? 1 : undefined}
        value={selectedValues}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onChange={handleChange}
        className='avatar-picker'
        open={dropdownVisible} // Prevent automatic opening
        optionRender={(option) => {
          return <Flex align='center' gap={10}>
            <span>
              <Avatar style={{ backgroundColor: generateHexDarkColor(option.value), fontSize: "12px" }}>{option.label.split(" ").map(a => a[0]).join("") }</Avatar>
            </span>
            {option.label}
          </Flex>
        }}
      >
        {users.map(user => (
          <Option disabled={readOnly} key={user.id} value={user.id}>{user.firstName+" "+user.lastName}</Option>
        ))}
      </Select>
    </div>
  );
};

export default AvatarPicker;
