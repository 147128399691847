import { AudioOutlined, ClockCircleOutlined, DeleteFilled, DeleteOutlined, EnterOutlined, LogoutOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Flex, Space, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom"
import AvatarPicker from "./AvatarPicker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ControlBar, useLiveKitRoom, useMaybeRoomContext, useRoomContext, useRoomInfo } from "@livekit/components-react";
import { get, slugify } from "../services/api";
import { setRoom } from "../features/room";
import dayjs from "dayjs";

function ScheduledTag({ scheduledAt }) {
  // Format the date based on whether it's today or not
  const formattedDate = dayjs(scheduledAt).isSame(dayjs(), 'day')
    ? dayjs(scheduledAt).format('HH:mm') // Only show time if today
    : dayjs(scheduledAt).format('YYYY-MM-DD HH:mm'); // Show date and time otherwise

  return (
    <Tag icon={<ClockCircleOutlined spin />} color="#108ee9" className="tag" style={{ marginRight: 0 }}>
      {formattedDate}
    </Tag>
  );
}

export default function MeetingCard({members, project, list, agenda, scheduledAt, notified, taskId}) {
  const roomName = slugify(project)+"-"+slugify(list)+'-'+taskId;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const room = useRoomContext();
  // const roomContext = useRoomContext();
  // const { room, htmlProps } = useLiveKitRoom();
  const currentRoomName = useSelector((state) => state.room);
  const users = useSelector((state) => state.users);
  const user = useSelector((state) => state.user);

  const leftRoom = async () => {
    dispatch(setRoom(null));
  };

  const joinedRoom = async () => {
    dispatch(setRoom(room.name));
    navigate('/meeting/'+roomName);
  };

  useEffect(() => {
    if(roomName == currentRoomName) {
      room.on('disconnected', leftRoom);
      room.on('connected', joinedRoom);
      return () => {
        room.off('disconnected', leftRoom);
        room.off('connected', joinedRoom);
      }
    }
    // Cleanup if room is undefined or roomName doesn't match
    return () => {};
  }, [room, roomName, currentRoomName])

  const enterRoom = async (e) => {
    e.stopPropagation(); // Prevent parent onClick from firing
    if(room) {
      await room.disconnect();
    }
    dispatch(setRoom(roomName));
  }

  return <div className="meeting-card-container" onClick={() => { navigate('/meeting/'+roomName); }}>
    <div className="meeting-card">
      <div className="">
        <Flex justify="space-between" align="center">
          <div style={{ fontWeight: 600, fontSize: "16px" }}>{project}</div>
          <div>
            <Badge count={notified} />&nbsp;&nbsp;
            <ScheduledTag scheduledAt={scheduledAt} />
          </div>
        </Flex>
        <Typography.Paragraph
          style={{ color: "#000", marginBottom: 0, marginTop: "10px" }}
          ellipsis={{
            rows: 2,
            expandable: false,
            tooltip: {
              placement: 'right',
              title: (
                <>
                  <b>{list}</b>
                  <br />
                  {agenda}
                </>
              )
            },
          }}
        >
        <b>{list}</b>
        <br />
          {agenda}
        </Typography.Paragraph>
      </div>
      <div className="actions-container" onClick={(e) => e.stopPropagation()}>
        <AvatarPicker users={users.filter(m => m.id != user.id)} defaultSelectedUsers={members.map(m => m.userId)} className="" />
        { roomName === currentRoomName && <ControlBar variation="minimal" /> }
        {/* { roomName === currentRoomName && <Button onClick={enterRoom} icon={<EnterOutlined />} size="small" shape="square"></Button> } */}
        { roomName !== currentRoomName && <Button onClick={enterRoom} icon={<EnterOutlined />} shape="square">Join</Button> }
      </div>
    </div>
  </div>
}
