// src/components/CreateTaskInput.js
import React, { useState } from 'react';
import { Input, Row, Col, Popover } from 'antd';

const CreateTaskInput = ({ onCreateTask }) => {
  const [taskTitle, setTaskTitle] = useState('');
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleInputChange = (e) => {
    setTaskTitle(e.target.value);
  };

  const handleFocus = () => {
    setIsPopoverVisible(true);
  };

  const handleBlur = () => {
    setIsPopoverVisible(false);
  };

  const handleCreateTask = () => {
    if (taskTitle.trim()) {
      onCreateTask(taskTitle);
      setTaskTitle(''); // Clear the input after creating the task
      setIsPopoverVisible(false); // Hide the popover
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid new line on Enter
      handleCreateTask();
    }
    // Shift+Enter naturally inserts a new line
  };

  const content = (
    <div>
      <p>Write a clear and concise task title.</p>
      <p>For example: "Design new logo" or "Fix login form bug".</p>
    </div>
  );

  return (
    <>
      <div className="task-input-container">
        <Row align="middle" gutter={16}>
          <Col flex="auto">
            {/* <Popover
              content={content}
              title="Task Title Tips"
              trigger="focus"
              placement='bottomLeft'
              open={isPopoverVisible}
              onOpenChange={setIsPopoverVisible}
            > */}
              <Input.TextArea
                rows={1}
                autoSize={{ minRows: 1, maxRows: 6 }}
                value={taskTitle}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                placeholder="add your task here..."
                style={{ borderRadius: '12px', padding: "10px 20px", border: "1px solid white", boxShadow: "0px 0px 13px -10px #000" }}
              />
            {/* </Popover> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateTaskInput;
