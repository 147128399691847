import { AudioConference, useRoomContext } from "@livekit/components-react";
import notificationSound from '../pages/layout/birds.wav';
import { useEffect, useState } from "react";

export default function HiddenMeeting() {
  // const room = useRoomContext(); // Access the room instance used by LiveKitRoom
  // const [audio] = useState(new Audio(notificationSound)); 
  // const [participantCount, setParticipantCount] = useState(0);

  // useEffect(() => {
  //   if(!room) { return; }
  //   const updateParticipantCount = () => {
  //     // Update the state with the current number of participants
  //     const count = room ? room.numParticipants : 0;
  //     setParticipantCount(count);
  //   };

  //   if (room) {
  //     // Listen for changes in participants
  //     room.on('participantConnected', updateParticipantCount);
  //     room.on('participantDisconnected', updateParticipantCount);
  //     // Set the initial participant count
  //     updateParticipantCount();
  //   }

  //   // Cleanup event listeners when the component unmounts or room changes
  //   return () => {
  //     if (room) {
  //       room.off('participantConnected', updateParticipantCount);
  //       room.off('participantDisconnected', updateParticipantCount);
  //     }
  //   };
  // }, [room]);

  // useEffect(() => {
  //   console.log({participantCount})
  //   if (participantCount == 1) {
  //     audio.play();
  //   } else {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   }
  //   return () => {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   }
  // }, [participantCount]);

  return <AudioConference style={{display: "none"}} />
}