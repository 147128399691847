import { PlusOutlined, DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Popconfirm, Progress, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import AssetCreate from '../components/AssetCreate';
import { get, xdelete } from "../services/api";
import moment from "moment";
import AssetTransactionCreate from "../components/AssetTransactionCreate";
import AssetEdit from "../components/AssetEdit";

export default function Assets() {
  const [assets, setAssets] = useState([]);
  const [asset, setAsset] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTransactModalOpen, setIsTransactModalOpen] = useState(false);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, resource) => {
        return <div className="avatar-with-profile">
          <Avatar size={40} style={{ minWidth: "40px" }} icon={<UserOutlined />} />
          <div className="heading-with-tagline">
            <a href="#">{name}</a>
            <span>{resource.position}</span>
          </div>
        </div>
      },
    },
    {
      title: 'Total Cost',
      render: ({ transactions }) => {
        let cost = 0;
        if (transactions) {
          transactions.forEach((t) => {
            cost += t.amount;
          })
        }
        return <div className="heading-with-tagline">
          <Typography.Text className="amount" type={cost < 0 ? "primary" : "primary"}>{cost}</Typography.Text>
          <span></span>
        </div>
      }
    },
    {
      title: 'Last Used',
      dataIndex: 'lastUsed',
      render: (lastUsed, asset) => {
        const remainingDays = moment().diff(moment(lastUsed), 'days');
        const percent = remainingDays * 100 / 90;
        let status = 'active';
        if (percent < 25) { status = 'success' }
        if (percent > 75) { status = 'exception' }
        return <div className="heading-with-tagline">
          <Progress percent={percent} showInfo={false} status={status} />
          <span>{lastUsed ? moment(lastUsed).fromNow() : null}</span>
          {/* <span>{lastUsed ?  : '' }</span> */}
        </div>
      }
    },
    {
      title: 'Actions',
      dataIndex: '',
      align: 'left',
      render: (asset) => {
        return <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Button onClick={() => showTransactModal(asset)} type="default" shape="circle" icon={<PlusOutlined />}></Button>
            <Button onClick={() => showEditModal(asset)} type="default" shape="circle" icon={<EditOutlined />}></Button>
            <Popconfirm
              title="Delete Asset"
              placement="bottomRight"
              description="Are you sure to delete this Asset?"
              onConfirm={() => deleteAsset(asset)}
              onCancel={console.log}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
            {/* <Button onClick={() => deleteAsset(asset)} type="default" shape="circle" icon={<DeleteOutlined />}></Button> */}
          </div>
        </>
      }
    },
  ]

  const deleteAsset = async (asset) => {
    await xdelete(`/assets/${asset.id}`);
    await refreshAssets();
  };

  const showEditModal = (asset) => {
    setAsset(asset)
    setIsEditModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showTransactModal = (asset) => {
    setAsset(asset);
    setIsTransactModalOpen(true);
  };

  const onAssetEdit = async () => {
    await refreshAssets();
  }

  const refreshAssets = async () => {
    const assets = await get("/assets", {
      include: [{
        relation: "transactions",
        scope: {
          fields: ["amount", "assetId"]
        }
      }]
    });
    return setAssets(assets);
  }
  const onAssetTransactionCreate = async () => {
    setAsset(null);
    await refreshAssets();
  }
  const selectType = async (type) => {
    if (type === 'all') { return await refreshAssets() }
    const assets = await get('/assets?filter={"where":{"type":"' + type + '"}}');
    return setAssets(assets);
  }
  useEffect(() => {
    refreshAssets();
  }, []);
  
  return <Flex gap={20}>
    <div style={{ marginLeft: '16px' }}>
      <Button type="primary" className="align bold" icon={<PlusOutlined />} style={{ margin: "10px 0 36px" }} onClick={showModal}>
        Add Asset
      </Button>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        size="small"
        style={{ width: "200px" }}
        onChange={selectType}
        items={[{
          label: `All`,
          key: 'all'
        }]}
      />
    </div>
    <Table style={{ flex: '1' }} scroll={{ y: window.innerHeight - 120 }} pagination={false} dataSource={assets} rowKey={'id'} columns={columns} />
    <AssetCreate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onAssetCreate={refreshAssets} />
    <AssetEdit isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} onAssetEdit={onAssetEdit} asset={asset} setAsset={setAsset} />
    <AssetTransactionCreate isModalOpen={isTransactModalOpen} setIsModalOpen={setIsTransactModalOpen} asset={asset} setAsset={setAsset} onAssetTransactionCreate={onAssetTransactionCreate} />
  </Flex>
}