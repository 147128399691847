import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, DatePicker, Flex, Input, List, Popconfirm, Popover, Slider, Space, TimePicker } from "antd";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import necessary plugins
import duration from 'dayjs/plugin/duration' // ES 2015
import { generateHexDarkColor } from "../services/api";
import moment from "moment";

dayjs.extend(isSameOrBefore); // Extend dayjs with the isSameOrBefore plugin
dayjs.extend(duration);

const formatDuration = (duration) => {
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  let formatted = '';
  if (days > 0) {
      formatted += `${days}d `;
  }
  if (hours > 0) {
      formatted += `${hours}h `;
  }
  if (minutes >= 0) {
      formatted += `${minutes}m`;
  }

  return formatted.trim();
};

export const millisecondsToTime = (milliseconds) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)));
  return `${hours ? hours.toString().padStart(2, '0') + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default function TrackedPicker ({ task, currentTaskTimerRef, currentTimeLog, onAddTrackedTime, onDeleteTrackedTime, onUpdateTrackedTime, users }) {
  const current = dayjs(); // Get current date and time
  const roundedCurrent = current.subtract(current.minute() % 10, 'minute').second(0); // Round down to the last 10 minutes
  const oneHourBefore = roundedCurrent.subtract(1, 'hour'); // Calculate one hour before
  const start = oneHourBefore ? oneHourBefore.hour() * 60 + oneHourBefore.minute() : 0;
  const end = roundedCurrent ? roundedCurrent.hour() * 60 + roundedCurrent.minute() : 1440;
  
  const [rangeValue, setRangeValue] = useState([start, end]);
  const [editTimeLog, setEditTimeLog] = useState(null);
  const [date, setDate] = useState(roundedCurrent);
  const [times, setTimes] = useState([oneHourBefore, roundedCurrent]);
  const [open, setOpen] = useState(false);

  const resetState = () => {
    setEditTimeLog(null);
    setDate(roundedCurrent);
    setRangeValue([start, end]);
    setTimes([oneHourBefore, roundedCurrent]);
  }

  const EditButton = ({timeLog}) => <Button
    type="secondary"
    danger={true}
    onClick={() => {
      if(!editTimeLog) {
        setEditTimeLog(timeLog);
        setDate(dayjs(timeLog.createdAt));
        setTimes([dayjs(timeLog.createdAt), dayjs(timeLog.stoppedAt)]);
      } else if(editTimeLog) {
        resetState();
      }
    }}
    shape="circle" icon={<EditOutlined />}>
  </Button>

  const DeleteButton = ({timeLog}) => <Popconfirm
    title="Delete Time Log"
    placement="bottomRight"
    description="Are you sure to delete this Time Log?"
    onConfirm={() => { onDeleteTrackedTime(timeLog); }}
    okText="Yes"
    cancelText="No">
    <Button size="small" icon={<DeleteOutlined />} />
  </Popconfirm>

  const handleCalendarChange = (value) => {
    value[0] = value[0].minute(Math.floor(value[0].minute()/10)*10);
    value[1] = value[1].minute(Math.floor(value[1].minute()/10)*10);
    if (value) {
      const start = value[0] ? value[0].hour() * 60 + Math.floor(value[0].minute()/10)*10 : 0;
      const end = value[1] ? value[1].hour() * 60 + Math.floor(value[1].minute()/10)*10 : 1440;
      setRangeValue([start, end]);
    }
    setTimes(value);
  }
  const handleSliderChange = (value) => {
    const startTime = convertMinutesToTime(value[0]);
    const endTime = convertMinutesToTime(value[1]);
    setTimes([startTime, endTime]);
    setRangeValue(value);
  };
  // Convert minutes to dayjs time
  const convertMinutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return dayjs().hour(hours).minute(mins).second(0);
  };
  
  const handleAdd = () => {
    if (!date || !times[0] || !times[1]) {
      console.error("Please ensure all fields are filled correctly.");
      return; // Ensuring all data is available
    }
  
    // Assume `times[0]` and `times[1]` are DayJS objects with correct time but not necessarily date
    let startDate = times[0].year(date.year());
    startDate = startDate.month(date.month());
    startDate = startDate.date(date.date());
  
    let endDate = times[1].year(date.year());
    endDate = endDate.month(date.month());
    endDate = endDate.date(date.date());

    onAddTrackedTime(task.id, startDate, endDate);
    resetState();
    setOpen(false);
  };

  const handleEdit = () => {
    if (!date || !times[0] || !times[1]) {
      console.error("Please ensure all fields are filled correctly.");
      return; // Ensuring all data is available
    }
  
    // Assume `times[0]` and `times[1]` are DayJS objects with correct time but not necessarily date
    let startDate = times[0].year(date.year());
    startDate = startDate.month(date.month());
    startDate = startDate.date(date.date());
  
    let endDate = times[1].year(date.year());
    endDate = endDate.month(date.month());
    endDate = endDate.date(date.date());

    onUpdateTrackedTime(editTimeLog, startDate, endDate);
    resetState();
    setOpen(false);
  };

  const formatMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60); // Get the total hours
    const minutes = totalMinutes % 60; // Get the remaining minutes

    return (`${hours} hours and ${minutes} minutes`);
  }

  return <Popover placement="bottom" trigger="click"
    open={open}
    onOpenChange={() => setOpen(!open)}
    content={ <>
      <Flex vertical style={{ marginBottom: "10px", backgroundColor: "#fff" }}>
        <Flex gap={10}>
          <DatePicker value={date} onChange={setDate} />
          <TimePicker.RangePicker
            value={times}
            minuteStep={10}
            format="hh:mm A"
            onCalendarChange={handleCalendarChange}
          />
        </Flex>
        <Flex gap={30} justify="space-between" align="center">
          <Slider
            style={{flex: 1, marginLeft: '20px', marginRight: '20px'}}
            range={{draggableTrack: true}}
            tooltip={{ open: false }}
            min={0}
            styles={{
              track: {
                // marginTop: '-3.5px'
              }
            }}
            max={1440}
            step={10}
            value={rangeValue}
            onChange={handleSliderChange}
            marks={{
              0: '00:00',
              1440: '24:00',
            }}
          />
        </Flex>
        <Flex gap={16} justify="space-between" align="center">
          <Alert style={{ flex: 1, padding: '4px 10px' }} message={formatMinutes(rangeValue[1]-rangeValue[0])+" will be logged"} type="info" showIcon />
          { !editTimeLog && <Button type="primary" onClick={handleAdd}>Add</Button>}
          { editTimeLog && <Button type="primary" onClick={handleEdit}>Edit</Button>}
        </Flex>
      </Flex>
      <List
        size="small"
        style={{maxHeight:'250px', overflowY:'scroll'}}
        bordered
        dataSource={(task.timeLogs || []) /**.filter(tl => tl.stoppedAt) */ }
        renderItem={(timeLog) => <List.Item style={{ paddingLeft: '10px', paddingRight: '0' }} actions={[ 
        // <EditButton timeLog={timeLog}/>, 
          timeLog.stoppedAt ? <DeleteButton timeLog={timeLog}/> : null ]}>
            <Flex justify="center" align="center" gap={10} style={{ width: "100%" }}>
              <Avatar className={!timeLog.stoppedAt ? "active" : ''} size={"small"} style={{ backgroundColor: generateHexDarkColor(timeLog.userId), fontSize: "10px" }}>{users.find(u => u.id == timeLog.userId).firstName[0]}{users.find(u => u.id == timeLog.userId).lastName[0]}</Avatar>
              <Flex vertical gap={0} style={{ flex: 1 }}>
                <span style={{ fontSize: "12px", fontWeight: 500, marginTop: "0px" }}>
                  {dayjs(timeLog.createdAt).format('ddd, MMM D, h:mm a')} - {dayjs(timeLog.stoppedAt || Date.now()).format('h:mm a')}
                  <span style={{
                    backgroundColor: "rgb(255 236 236)", padding: "1px 8px", borderRadius: "8px", marginLeft: "20px"
                  }}>{formatDuration(dayjs.duration(dayjs(timeLog.stoppedAt).diff(dayjs(timeLog.createdAt))))}</span>
                </span>
              </Flex>
            </Flex>
        </List.Item>}
      />
    </> }>
    <div className="avatar-with-profile" >
      <div className="heading-with-tagline">
        <span ref={currentTimeLog?.taskId === task.id ? currentTaskTimerRef : null}>{millisecondsToTime((task.timeLogs || []).reduce((total, log) => {
          return total + (new Date(log.stoppedAt || Date.now()) - new Date(log.createdAt));
        }, 0))}</span>
        {currentTimeLog?.taskId === task.id && <span>{millisecondsToTime((task.timeLogs || []).reduce((total, log) => {
          return total + (log.stoppedAt ? (new Date(log.stoppedAt || Date.now()) - new Date(log.createdAt)) : 0);
        }, 0))}</span>}
      </div>
    </div>
  </Popover>
}