import { Button, Checkbox, DatePicker, Flex, Form, Input, InputNumber, Modal } from "antd";
import { post } from "../services/api";
import { useState } from "react";
import AvatarPicker from "./AvatarPicker";
import { useSelector } from "react-redux";

export default function HumanCreate({ isModalOpen, setIsModalOpen, onHumanCreate }) {
  const [isFemale, setIsFemale] = useState(false);
  const [birthDate, setBirthDate] = useState();
  const [user, setUser] = useState(null);
  const users = useSelector((state) => state.users);



  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      isFemale: isFemale,
      birthDate: birthDate,
      userId: user

    };
    if (!values.endDate) {
      delete data.endDate;
    }
    const human = await post('/humans', data);
    setIsModalOpen(false);
    onHumanCreate(human);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  const handleFormValuesChange = (changedValues, allValues) => {
    if ('birthDate' in changedValues) {
      setBirthDate(changedValues.birthDate);
      // alert(changedValues.transactionType);
    }
    if ('isFemale' in changedValues) {
      
      console.log("isFemale : " + isFemale)
    }
  }

  // console.log(user)


  return <Modal title="Add Human" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addHumanForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addHumanForm"
      name="basic"
      layout="vertical"
      onValuesChange={handleFormValuesChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Please input human name!',
          }]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Position"
          name="position"
          rules={[{
            required: true,
            message: 'Please input human position!',
          }]}>
          <Input />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="CTC"
          name="ctc"
          rules={[{
            required: true,
            message: 'Please input human ctc!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Employee ID"
          name="employeeId"
          rules={[{
            required: true,
            message: 'Please input employeeId!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Birthdate"
          name="birthDate"
          rules={[{
            required: true,
            message: 'Please Select Birth Date!',
          }]}>
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Are You Female ?"
          name="isFemale"
        >
          <Checkbox onChange={()=>{
            setIsFemale(!isFemale)
          }}></Checkbox>       </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Select User"
          name="userId"
        >
          <AvatarPicker defaultSelectedUsers={[]} users={users} onAddUser={setUser} onRemoveUser={() => setUser(null)} single={true} />
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}