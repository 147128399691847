import { ControlBar, LiveKitRoom, PreJoin, useLiveKitRoom, useMaybeRoomContext, useRoomContext, VideoConference } from "@livekit/components-react";
import '@livekit/components-styles';
import { useEffect, useState } from "react";
import { get, livekitServerUrl, xdelete } from '../services/api';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Flex } from "antd";
import { setRoom } from "../features/room";
import { setNotifications } from "../features/notifications";

export default function Meeting () {
  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.notifications);
  const room = useRoomContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const rejoin = async () => {
    dispatch(setRoom(id));
  }
  const complete = async () => {
    await xdelete(`/users/${user.id}/meetings`, { taskId: id.split("-").pop() });
  }
  return <div style={{height: "100%"}}>
    { id === room.name && room.state === 'connected' && <div data-lk-theme="default" style={{ height: "100%" }}>
      <VideoConference />
    </div> }
    { id === room.name && room.state !== 'connected' && <>
      <Flex align="center" justify="center" vertical style={{ height: "100vh" }}>
        <div style={{ flex: 0, marginBottom: "20px", textAlign: "center" }}>
          <h3>{id}</h3>
          <div>Thanks, {user.firstName} {user.lastName}</div>
        </div>
        <Flex align="center" justify="center" style={{ height: "100vh", flex: 0 }} gap={20}>
          <Button
            style={{ backgroundColor: "#af5151", fontWeight: "bold" }}
            type="primary"
            onClick={() => { rejoin() }}
          >Rejoin Meeting</Button>
          <Button
            style={{ backgroundColor: "#518f51", fontWeight: "bold" }}
            type="primary"
            onClick={() => { complete() }}
          >Mark Meeting as Completed</Button>
          <Button
            style={{ fontWeight: "bold" }}
            type="primary"
            onClick={() => {  }}
          >Reschedule Meeting</Button>
        </Flex>
      </Flex>
    </> }
    { id !== room.name && <>
      <Flex align="center" justify="center" vertical style={{ height: "100vh" }}>
        <div style={{ flex: 0, marginBottom: "20px", textAlign: "center" }}>
          <h3>{id}</h3>
          <div>Hi, {user.firstName} {user.lastName}</div>
        </div>
        <Flex align="center" justify="center" style={{ height: "100vh", flex: 0 }} gap={20}>
          <Button
            style={{ backgroundColor: "#518f51", fontWeight: "bold" }}
            type="primary"
            onClick={() => { rejoin() }}
          >Join Meeting Now</Button>
          <Button
            style={{ backgroundColor: "#af5151", fontWeight: "bold" }}
            type="primary"
            onClick={() => { complete() }}
          >Discard Meeting</Button>
          <Button
            style={{ fontWeight: "bold" }}
            type="primary"
            onClick={() => {  }}
          >Reschedule Meeting</Button>
        </Flex>
      </Flex>
    </> }
  </div>;
}