import { createSlice } from '@reduxjs/toolkit';

const projectsSlice = createSlice({
  name: 'projects',
  initialState: [],
  reducers: {
    setProjects: (state, action) => {
      window.projects = action.payload;
      return action.payload;
    },
    addProject: (state, action) => {
      state.push(action.payload);
    },
    updateProject: (state, action) => {
      const index = state.findIndex(project => project.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    deleteProject: (state, action) => {
      return state.filter(project => project.id !== action.payload.id);
    },
    addListToProject: (state, action) => {
      const list = action.payload;
      const project = state.find(project => project.id === list.projectId);
      if (project) {
        project.lists = [...(project.lists || []), list];
      }
      console.log(project);
    },
    updateListInProject: (state, action) => {
      const list = action.payload;
      const project = state.find(project => project.id === list.projectId);
      if (project && project.lists) {
        const listIndex = project.lists.findIndex(item => item.id === list.id);
        if (listIndex !== -1) {
          project.lists[listIndex] = { ...project.lists[listIndex], ...list };
        }
      }
    },
    deleteListFromProject: (state, action) => {
      const { projectId, id } = action.payload;
      const project = state.find(project => project.id === projectId);
      if (project && project.lists) {
        project.lists = project.lists.filter(list => list && list.id !== id);
      }
    },
  },
});

export const projectsReducer = projectsSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setProjects,
  addProject,
  updateProject,
  deleteProject,
  addListToProject,
  updateListInProject,
  deleteListFromProject,
} = projectsSlice.actions;