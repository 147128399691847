import { DeleteOutlined, EditOutlined, PauseCircleFilled, PhoneOutlined, PlayCircleFilled, SyncOutlined, VideoCameraAddOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex, Form, Popover, Popconfirm, Select, Space, Table, Tag, Typography, List, Avatar } from "antd";
import { useEffect, useRef, useState } from "react";
import { get, patch, post, slugify, xdelete } from "../services/api";
import AvatarPicker from './AvatarPicker';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTimeLog } from "../features/currentTimeLog";
import CreateTaskInput from "./CreateTaskInput";
import EstimatedTimePicker from "./EstimatedTimePicker";
import StatusPicker from "./StatusPicker";
import DueDatePicker from "./DueDatePicker";
import TrackedPicker, { millisecondsToTime } from "./TrackedPicker";
import { setRoom } from "../features/room";
import { removeTaskEvent, removeTaskEvents, setTaskEvents } from "../features/taskEvents";
const { RangePicker } = DatePicker;

const { Option } = Select;

export default function Tasks({
  custom
}) {
  const dispatch = useDispatch();
  const currentTaskTimerRef = useRef();
  const currentTimeLog = useSelector((state) => state.currentTimeLog);
  const list = useSelector((state) => state.list);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const taskEvents = useSelector((state) => state.taskEvents);
  const [tasks, setTasks] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  /*** START TASKS CRUD OPERATIONS */

  const getTasks = async () => {
    if (custom == 'mine') {
      return await get(`/users/${user.id}/tasks`, {
        include: [{
          relation: 'taskAccesses'
        }, {
          relation: 'timeLogs',
          scope: {
            order: ['createdAt DESC'] // Add sorting here
          }
        },{
          relation : 'list',
          scope : {
            include : [{
              relation : 'project'
            }]
          }
        }],
        order: ['dueDate ASC'],
        where: {
          status: { neq: 'done' }, // Exclude tasks where status is "done"
        },
      })
    } else if (custom == 'all') {
      // TODO: API Calling Twice
      return get(`/users/${user.id}/everything`, {
        include: [{
          relation: 'taskAccesses'
        }, {
          relation: 'timeLogs',
          scope: {
            order: ['createdAt DESC'] // Add sorting here
          }
        },{
          relation : 'list',
          scope : {
            include : [{
              relation : 'project'
            }]
          }
        }],
        order: ['dueDate ASC'],
        where: {
          status: { neq: 'done' }, // Exclude tasks where status is "done"
        },
      })
    } else if (list && list.id) {
      return await get(`/users/${user.id}/lists/${list.id}/tasks`);
    } else {
      return [];
    }
  }

  const addTask = async (title) => {
    await post(`/users/${user.id}/tasks`, {
      title, listId: list.id
    });
  }

  const updateTask = async (id, property, value) => {
    var data = {};
    data[property] = value;
    await patch(`/users/${user.id}/tasks`, data, { id });
  };

  const deleteTasks = async () => {
    await xdelete(`/users/${user.id}/tasks`, {
      id: {
        inq: selectedRowKeys
      }
    });
    setSelectedRowKeys([]);
  };

  const refreshTasks = async () => {
    const tasks = await getTasks();
    setTasks(
      tasks
        .map(task => {
          if (task.timeLogs && task.timeLogs.length > 0) {
            task.timeLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          }
          return task;
        })
    );
  }

  /*** END TASKS CRUD OPERATIONS */

  /*** START TASK ASSIGNEE CRUD OPERATIONS */

  const onAddUser = async (user, task, role) => {
    const taskAccess = (task.taskAccesses || []).find(ta => ta.userId === user);
    if (taskAccess && taskAccess.role === 'watcher') {
      await patch(`/tasks/${task.id}/task-accesses`, {
        role
      }, {
        userId: user
      });
    } else {
      await post(`/tasks/${task.id}/task-accesses`, {
        role,
        userId: user
      });
    }
  }

  const onRemoveUser = async (userId, task, role) => {
    if (role === 'assignee' && user.id == userId) {
      await patch(`/tasks/${task.id}/task-accesses`, {
        role: 'watcher'
      }, {
        userId
      });
    } else {
      await xdelete(`/tasks/${task.id}/task-accesses`, {
        userId
      });
    }
  }

  /*** END TASK ASSIGNEE CRUD OPERATIONS */

  /*** START TASK TRACKING CRUD OPERATIONS */

  const startTracking = async (taskId) => {
    await post(`/users/${user.id}/time-logs`, { taskId });
  }

  const stopTracking = async () => {
    await patch(`/users/${user.id}/time-logs`, { stoppedAt: new Date() }, {
      id: currentTimeLog.id
    });
  }

  /*** END TASK TRACKING CRUD OPERATIONS */

  useEffect(() => {
    console.log("Refresh Task", list, custom);
    if((list && list.id && !custom) || (custom && !list)) {
      refreshTasks();
    }
    return () => {
      setTasks([]);
    }
  }, [list && list.id, custom]);

  const onAddTrackedTime = async (taskId, createdAt, stoppedAt) => {
    await post(`/users/${user.id}/time-logs`, {
      createdAt,
      stoppedAt,
      taskId
    });
    // if(timeLog) {
    //   onUpdateTask(tasks.map(task => {
    //     return task.id === taskId ? { ...task, timeLogs: [timeLog, ...(task.timeLogs || [])] } : task
    //   }));
    // }
  }

  const onDeleteTrackedTime = async ({id, taskId}) => {
    await xdelete(`/users/${user.id}/time-logs`,{id});
    // if(deleted.count) {
    //   onUpdateTask(tasks.map(task => {
    //     return task.id === taskId ? { ...task, timeLogs: task.timeLogs.filter(tl => tl.id !== id) } : task
    //   }));
    // }
  }

  const onUpdateTrackedTime = async ({id, taskId}, createdAt, stoppedAt) => {
    // const updated = await patch(`/users/${user.id}/time-logs`, { 
    //   createdAt,
    //   stoppedAt
    //  }, { id });
    // if(updated.count) {
    //   onUpdateTask(tasks.map(task => {
    //     return task.id === taskId ? { ...task, timeLogs: task.timeLogs.map(tl => tl.id === id ? {
    //       ...tl,
    //       createdAt,
    //       stoppedAt
    //     } : tl) } : task
    //   }));
    // }
  }

  const startMeeting = async (task) => {
    var meeting = await post(`/users/${user.id}/meetings`, {
      taskId: task.id
    });
    const project = meeting.task?.list?.project?.name;
    const list = meeting.task?.list?.name;
    const roomName = slugify(project)+"-"+slugify(list)+'-'+task.id;
    dispatch(setRoom(roomName));
  }

  // -- for updating the timer on a task
  useEffect(() => {
    if (!currentTimeLog) {
      return;
    }
    const updateTimer = () => {
      var totalTime = dayjs().diff(dayjs(currentTimeLog.createdAt));
      var elem = currentTaskTimerRef.current;
      if (elem) {
        elem.innerHTML = millisecondsToTime(totalTime);
      }
    };
    var interval = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [currentTaskTimerRef.current, currentTimeLog])
  // -- for updating the timer on a task

  useEffect(() => {
    // TODO: Warning: Cannot update a component (`HeaderItem`) while rendering a different component (`Task`). To locate the bad setState() call inside `Task`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    if (taskEvents.length > 0) {
      setTasks((currentTasks) => {
        // Start with a copy of the current tasks from the functional update
        let updatedTasks = [...currentTasks];
  
        taskEvents.forEach((taskEvent) => {
          switch (taskEvent.entity) {
            case 'Task':
              const task = taskEvent.data;
              switch (taskEvent.method) {
                case 'POST':
                  if((list && list.id == task.listId) || custom == 'all') {
                    updatedTasks = [...updatedTasks, task];
                  }
                  break;
  
                case 'PUT':
                  updatedTasks = updatedTasks.map(t => t.id === task.id ? { ...t, ...task } : t);
                  break;
  
                case 'DELETE':
                  updatedTasks = updatedTasks.filter(t => t.id !== task.id);
                  break;
  
                default:
                  break;
              }
              break;
            case 'TaskAccess':
              const taskAccess = taskEvent.data;
              switch (taskEvent.method) {
                case 'POST':
                  if (custom == 'mine' && taskAccess.userId == user.id) {
                    updatedTasks = [...updatedTasks, taskAccess.task];
                  } else if (list && (list.listAccesses || []).find((la) => la.userId == user.id && !la.access) && taskAccess.userId == user.id) {
                    updatedTasks = [...updatedTasks, taskAccess.task];
                  } else {
                    if(!updatedTasks.some((ut) => ut.id === taskAccess.taskId) && custom == 'all') {
                      updatedTasks = [...updatedTasks, taskAccess.task];
                    } else {
                      updatedTasks = [...updatedTasks.map((ut) => {
                        return ut.id == taskAccess.taskId ? { ...ut, taskAccesses: [ ...(ut.taskAccesses || []), taskAccess ] } : ut
                      })]
                    }
                  }
                  break;
                case 'PUT':
                  updatedTasks = [...updatedTasks.map((ut) => {
                    return ut.id == taskAccess.taskId ? { ...ut, taskAccesses: [ ...(ut.taskAccesses || []).map((ta) => {
                      return ta.userId == taskAccess.userId ? { ...ta, ...taskAccess } : ta
                    }) ] } : ut
                  })]
                  break;
                case 'DELETE':
                  if(custom == 'all' && taskAccess.userId == user.id && taskAccess.listAccess == 0) {
                    updatedTasks = updatedTasks.filter(t => t.id !== taskAccess.taskId);
                  } else if (custom == 'mine' && taskAccess.userId == user.id) {
                    updatedTasks = updatedTasks.filter(t => t.id !== taskAccess.taskId);
                  } else if (list && (list.listAccesses || []).find((la) => la.userId == user.id && !la.access) && taskAccess.userId == user.id) {
                    updatedTasks = updatedTasks.filter(t => t.id !== taskAccess.taskId);
                  } else {
                    updatedTasks = [...updatedTasks.map((ut) => {
                      return ut.id == taskAccess.taskId ? { ...ut, taskAccesses: [ ...(ut.taskAccesses || []).filter((ta) => {
                        return ta.userId !== taskAccess.userId
                      }) ] } : ut
                    })]
                  }
                  break;
                default:
                  break;
              }
              break;
            case 'TimeLog':
              const timeLog = taskEvent.data;
              switch(taskEvent.method) {
                case 'POST':
                  updatedTasks = [...updatedTasks.map((ut) => {
                    return ut.id == timeLog.taskId ? { ...ut, timeLogs: [ timeLog, ...(ut.timeLogs || []) ] } : ut
                  })];
                  break;
                case 'PUT':
                  updatedTasks = [...updatedTasks.map((ut) => {
                    return ut.id == timeLog.taskId ? { ...ut, timeLogs: [ ...(ut.timeLogs || []).map((tl) => {
                      return tl.id == timeLog.id ? { ...tl, ...timeLog } : tl
                    }) ] } : ut
                  })];
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
          // Remove the processed taskEvent after processing
          // dispatch(removeTaskEvent(taskEvent.id));
        });
  
        return updatedTasks; // Return the fully updated tasks list
      });
      taskEvents.forEach((taskEvent) => dispatch(removeTaskEvent(taskEvent.id)));
      // TODO: fix above one it is not optimized solution
    }
  }, [taskEvents]);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (title, task) => {
        return <div className="avatar-with-profile" >
          {task.id === currentTimeLog?.taskId ? <PauseCircleFilled onClick={stopTracking} style={{ fontSize: "25px", color: "#ff398b" }} /> : <PlayCircleFilled onClick={() => startTracking(task.id)} style={{ fontSize: "25px", color: "#398bff" }} />}
          <div className="heading-with-tagline heading-with-tagline-absolute" style={{ maxWidth: "calc(100% - 30px)" }}>
            <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>
            {/* <span><Progress size={"small"} percent={20} style={{ margin: 0, maxWidth: '150px' }}/></span> */}
            <span>{list == null && `${task?.list?.project?.name} / ${task?.list?.name}`}</span>
          </div>
        </div>
      },
    },
    {
      title: 'Members',
      width: "180px",
      render: (task) => {
        var currentTimeLoggers = (task.timeLogs || []).filter(tl => !tl.stoppedAt).map(tl => tl.userId);
        if(currentTimeLog?.taskId == task.id) {currentTimeLoggers.push(user.id)}
        return <Flex>
          <AvatarPicker maxCount={5} active={currentTimeLoggers} defaultSelectedUsers={[
            ...new Set([
              ...(task.taskAccesses || []).filter(ta => ta.role === 'assignee' || ta.userId != user.id).map(ta => ta.userId), 
              ...(currentTimeLog && currentTimeLog.taskId === task.id ? [currentTimeLog.userId] : [])
            ])
          ]} onAddUser={(u) => onAddUser(u, task, 'assignee')} onRemoveUser={(u) => onRemoveUser(u, task, 'assignee')} users={users} />
          { (task.taskAccesses || []).filter(ta => ta.userId !== user.id).length > 0 && <Button
            className="call-action"
            type={"link"}
            shape="circle"
            onClick={() => {startMeeting(task)}}
            icon={<PhoneOutlined />} /> }
        </Flex>
      },
    },
    {
      title: 'Due Date',
      width: "160px",
      render: (task) => {
        return <DueDatePicker task={task} onUpdateDueDate={(dueDate) => updateTask(task.id, 'dueDate', dueDate)} />
      },
    },
    {
      title: 'Tracked',
      width: "100px",
      dataIndex: 'tracked',
      render: (title, task) => {
        return <TrackedPicker task={task} currentTimeLog={currentTimeLog} onAddTrackedTime={onAddTrackedTime} onDeleteTrackedTime={onDeleteTrackedTime} onUpdateTrackedTime={onUpdateTrackedTime} currentTaskTimerRef={currentTaskTimerRef} users={users}/>
      },
    },
    {
      title: 'Estimated Time',
      width: "150px",
      render: (task) => {
        return <EstimatedTimePicker task={task} onUpdateEstTime={(estTime) => updateTask(task.id, 'estTime', estTime)} />
      },
    },
    {
      title: 'Status',
      width: "150px",
      render: (task) => {
        return <StatusPicker task={task} onUpdateStatus={(status) => updateTask(task.id, 'status', status)} />
      },
    },
  ]

  return <div style={{ paddingLeft: "0px", paddingRight: "0px" }}>
    <Flex gap={40} style={{ position: "relative" }}>
      {selectedRowKeys.length > 0 && <div className="selectedRowsActions">
        <Popconfirm
          title="Delete Task"
          placement="bottomLeft"
          description="Are you sure to delete this task?"
          onConfirm={deleteTasks}
          onCancel={console.log}
          okText="Yes"
          cancelText="No"
        >
          <Button shape="circle" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>}
      <Table
        // style={{ flex: "1" }}
        scroll={{
          y: window.innerHeight - (list ? 180 : 120),
          x: 500,
        }}
        // size="small"
        pagination={false}
        dataSource={tasks
          .sort((a, b) => {
            if (!a.dueDate) return 1; // Place tasks without a dueDate at the bottom
            if (!b.dueDate) return -1; // Place tasks with a dueDate at the top
            return new Date(a.dueDate) - new Date(b.dueDate); // Sort by dueDate in ascending order
          })
        }
        rowKey={'id'}
        columns={columns}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
        }}
      />
    </Flex>
    { list && <Flex gap={0} style={{ flex: 0 }}>
      <div style={{ flex: 1, margin: "10px 0px 10px 30px", boxShadow: "0px -15px 45px 15px #f5f5f5", zIndex: 1 }}>
        <CreateTaskInput onCreateTask={addTask} />
      </div>
    </Flex> }
  </div>
}