import { createSlice } from "@reduxjs/toolkit";

const listSlice = createSlice({
    name : "list",
    initialState : null,
    reducers:{
        setList : (state,action) =>{
            return action.payload
        },
        addListAccessToList: (state, action) => {
          const { listId } = action.payload;
          if (state && state.id === listId) {
            state.listAccesses.push(action.payload);
          }
        },
        updateListAccessInList: (state, action) => {
          const { listId, id } = action.payload;
          if (state && state.id === listId) {
            const index = state.listAccesses.findIndex(item => item.id === id);
            if (index !== -1) {
              state.listAccesses[index] = {...state.listAccesses[index], ...action.payload};
            }
          }
        },
        deleteListAccessFromList: (state, action) => {
          const { listId, userId } = action.payload;
          if (state && state.id === listId) {
            state.listAccesses = state.listAccesses.filter(item => item.userId !== userId);
          }
        },
    }
})



export const listReducer =  listSlice.reducer

export const {setList, addListAccessToList, updateListAccessInList, deleteListAccessFromList} = listSlice.actions