import React, { useEffect, useState } from 'react';

export default function Player() {
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    // Fetch the live video ID from the YouTube API
    const fetchLiveStream = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${'UCcJPOSH2g_9c2wHPeIok2Ug'}&eventType=live&type=video&key=${'AIzaSyAt9Kf1CkH_bF_tssrBBDfCdCD-UnXeN4I'}`
        );
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          setVideoId(data.items[0].id.videoId);
        } else {
          console.log('No live stream currently available.');
        }
      } catch (error) {
        console.error('Error fetching live stream:', error);
      }
    };

    fetchLiveStream();
  }, []);

  return null;
  return (
    <div style={{ maxWidth: '100%', height: "100%", background: '#000' }}>
      {videoId || true ? (
        <iframe
          src={`https://www.youtube.com/embed/${'n-2ZCOhahuE'}?autoplay=1`}
          title="YouTube Live Stream"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        />
      ) : (
        <p style={{ backgroundColor: '#000', height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff" }}>
          No live stream available
        </p>
      )}
    </div>
  );
}
